import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import React from "react";

import {
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

// project imports
import NavItem from "./../NavItem";
import NavCollapse from "./../NavCollapse";
import { useSelector } from "react-redux";

const PREFIX = "NavGroup";

const classes = {
  menuCaption: `${PREFIX}-menuCaption`,
  subMenuCaption: `${PREFIX}-subMenuCaption`,
  menuDivider: `${PREFIX}-menuDivider`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.menuCaption}`]: {
    ...theme.typography.menuCaption,
  },

  [`& .${classes.subMenuCaption}`]: {
    ...theme.typography.subMenuCaption,
  },

  [`& .${classes.menuDivider}`]: {
    marginTop: "2px",
    marginBottom: "10px",
  },
}));

const listItemText = {
  fontWeight: "medium",
};

//-----------------------|| SIDEBAR MENU LIST GROUP ||-----------------------//

const NavGroup = ({ item }) => {
  const { currentAdmin } = useSelector((state) => state.authentication);
  const [open, setOpen] = React.useState(
    item.title === "Franchisees" ? true : false
  );

  const handleClick = () => {
    setOpen(!open);
  };

  // menu list collapse & items
  const items = item.children.map((menu) => {
    switch (menu.type) {
      case "collapse":
        if (
          currentAdmin &&
          currentAdmin.modules?.some((m) => m === menu.menuId)
        ) {
          return <NavCollapse key={menu.id} menu={menu} level={1} />;
        }
        break;
      case "item":
        if (
          currentAdmin &&
          currentAdmin.modules?.some((m) => m === menu.menuId)
        ) {
          return <NavItem key={menu.id} item={menu} level={1} />;
        }
        break;
      // return <NavItem key={menu.id} item={menu} level={1} />;
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
    return null;
  });

  return (
    <Root>
      <List
        subheader={
          item.title && (
            <ListItemButton onClick={handleClick}>
              <ListItemText
                primaryTypographyProps={{ style: listItemText }}
                className={classes.menuCaption}
                primary={item.title}
              />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          )
        }
      >
        <Collapse in={open} timeout="auto" unmountOnExit>
          {items}
        </Collapse>
      </List>

      {/* group divider */}
      <Divider className={classes.menuDivider} />
    </Root>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object,
};

export default NavGroup;
