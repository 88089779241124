import React from "react";

// mui-material
import { Typography } from "@mui/material";

// state management
import { useSelector } from "react-redux";

// project imports
import NavGroup from "layout/MainLayout/Sidebar/MenuList/NavGroup";
import buildMenuItems from "menu-items/index";

//-----------------------|| SIDEBAR MENU LIST ||-----------------------//
const MenuList = () => {
  const { currentAdmin } = useSelector((state) => state.authentication);
  const menuItems = buildMenuItems(currentAdmin);

  const navItems = menuItems.items.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return navItems;
};

export default MenuList;
