import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import PropTypes from "prop-types";

// mui-material
import { AppBar, CssBaseline, Toolbar, Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

// assets
import { ChevronRight as IconChevronRight } from "@mui/icons-material";

// third-party
import classnames from "classnames";

// project imports
import { drawerWidth, cignallThemeColor } from "themes/constants";
import { SET_MENU } from "store/navigationActions";
import Breadcrumbs from "ui-component/extended/Breadcrumbs";
//import Customization from "layout/Customization";
import Header from "layout/MainLayout/Header";
import Sidebar from "layout/MainLayout/Sidebar";
import buildMenuItems from "menu-items/index";

const PREFIX = "MainLayout";

const classes = {
  root: `${PREFIX}-root`,
  appBar: `${PREFIX}-appBar`,
  appBarWidth: `${PREFIX}-appBarWidth`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
  },

  [`& .${classes.appBar}`]: {
    //backgroundColor: theme.palette.background.default,
    backgroundColor: cignallThemeColor,
  },

  [`& .${classes.appBarWidth}`]: {
    transition: theme.transitions.create("width"),
    //backgroundColor: theme.palette.background.default,
    backgroundColor: cignallThemeColor,
  },

  [`& .${classes.content}`]: {
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("md")]: {
      marginLeft: -(drawerWidth - 20),
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },

  [`& .${classes.contentShift}`]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.down("lg")]: {
      marginLeft: "20px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "10px",
    },
  },
}));

// check env
const envName = process.env.REACT_APP_ENV_NAME;
const envVersion = process.env.REACT_APP_VERSION;

//-----------------------|| MAIN LAYOUT ||-----------------------//

const MainLayout = ({ children }) => {
  const { currentAdmin } = useSelector((state) => state.authentication);

  const dispatch = useDispatch();
  const leftDrawerOpened = useSelector((state) => state.navigation.opened);

  // Handle left drawer
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  const menuItems = buildMenuItems(currentAdmin);

  return (
    <div>
      <Root className={classes.root}>
        <CssBaseline />
        {/* header */}
        <AppBar
          position="fixed"
          className={!leftDrawerOpened ? classes.appBarWidth : classes.appBar}
        >
          <Toolbar>
            <Header drawerToggle={handleLeftDrawerToggle} />
          </Toolbar>
        </AppBar>

        {/* drawer */}
        <Sidebar
          drawerOpen={leftDrawerOpened}
          drawerToggle={handleLeftDrawerToggle}
        />

        {/* main content */}
        <main
          className={classnames([
            classes.content,
            {
              [classes.contentShift]: leftDrawerOpened,
            },
          ])}
        >
          {/* breadcrumb */}
          <Breadcrumbs
            separator={IconChevronRight}
            navigation={menuItems}
            icon
            title
            rightAlign
          />
          <div>{children}</div>
          <Grid
            item
            style={{
              bottom: 0,
              position: "fixed",
              width: `${!leftDrawerOpened ? "100%" : "80%"}`,
              textAlign: "center",
            }}
          >
            <Typography
              variant="subtitle1"
              style={{
                bottom: 0,
                position: "fixed",
                width: `${!leftDrawerOpened ? "100%" : "80%"}`,
                textAlign: "center",
              }}
            >
              {new Date().getFullYear()} &copy; Cignall (
              {envName.toLocaleUpperCase()}) - {envVersion}
            </Typography>
          </Grid>
        </main>
        {/*Setting to enable dark and light layout*/}
        {/* <Customization /> */}
        <Outlet />
      </Root>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
