import React, { useEffect } from "react";

// state management
import { useDispatch } from "react-redux";
import { actionCreators as ac } from "./store/authenticationActionReducer";

// third-party library
import { useLocation, useNavigate } from "react-router-dom";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// project imports
import AppRoutes from "./routes"; // routing

// Disable logging when not in development mode
if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
  console.debug = () => {};
  console.info = () => {};
  console.warn = () => {};
}

const router = createBrowserRouter([{ path: "*", element: <Root /> }]);

function Root() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.addEventListener("beforeunload", () =>
      localStorage.removeItem("persist:root")
    );
    dispatch(ac.requestCurrentAdmin(location, navigate));
    // TODO: Need to revisit react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AppRoutes />;
}

//export default App;
export default function App() {
  return <RouterProvider router={router} />;
}
